import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialImportDeleteForm from "./MaterialImportDeleteForm";
import MaterialImportUpdateForm from "./MaterialImportUpdateForm";
import { getMaterialImports } from "../../actions/materialImports";
import MaterialImportCreateForm from "./MaterialImportCreateForm";
import MaterialImportItem from "./MaterialImportItem";
import { Plus } from "react-feather";
import TableSpinner from "../layout/TableSpinner";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { usePermission } from '../../hooks/usePermission';
import { getMaterialOptions } from '../../actions/material';
import { getSupplierOptions } from '../../actions/supplier';
import { getFirmOptions } from '../../actions/user';

const MaterialImports = () => {
    const dispatch = useDispatch();
    const { userCan } = usePermission();
    const { materialImport } = useSelector(state => state);
    const { supplier } = useSelector(state => state);
    const { material } = useSelector(state => state);
    const { user } = useSelector(state => state);
    const { 
        materialImports, 
        loading, 
        numberOfPages, 
        currentPage
    } = materialImport;
    const { suppliers_DATA } = supplier;
    const { material_options } = material;
    const { firm_options } = user;

    const [show, setShow] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedFirm, setSelectedFirm] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);

    useEffect(() => {
        dispatch(getSupplierOptions());
        dispatch(getMaterialOptions());
        dispatch(getFirmOptions());
    }, [dispatch]);

    useEffect(() => {
        if (selectedFirm) {
            dispatch(getMaterialOptions({ firm_id: selectedFirm.value }));
        } else {
            dispatch(getMaterialOptions());
        }
    }, [selectedFirm, dispatch]);

    useEffect(() => {
        const filters = {};
        if (startDate) {
            filters.from_date = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            filters.to_date = endDate.toISOString().split('T')[0];
        }
        if (selectedSupplier) {
            filters.supplier_id = selectedSupplier.value;
        }
        if (selectedFirm) {
            filters.firm_id = selectedFirm.value;
        }
        if (selectedMaterial) {
            filters.material_id = selectedMaterial.value;
        }
        dispatch(getMaterialImports(1, filters));
    }, [startDate, endDate, selectedSupplier, selectedFirm, selectedMaterial, dispatch]);

    if (!userCan('view material imports')) {
        return <div>Siz material importlarni ko'rish uchun ruxsatga ega emassiz.</div>;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const supplierOptions = suppliers_DATA ? suppliers_DATA.map(supplier => ({
        value: supplier.id,
        label: supplier.name
    })) : [];

    const firmOptions = firm_options ? firm_options.map(firm => ({
        value: firm.id,
        label: firm.name
    })) : [];

    const materialOptions = material_options ? material_options.map(material => ({
        value: material.id,
        label: material.name
    })) : [];

    return (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3">Material importlari</h1>
                    </div>
                    <div className="col-md-12 col-lg-4 text-end">
                        {userCan('create material imports') && (
                            <button className="btn btn-primary" onClick={handleShow}>
                                <Plus size={18}/> Import qo'shish
                            </button>
                        )}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-12 col-lg-3">
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            className="form-control"
                            placeholderText="Sana oralig'ini tanlang"
                        />
                    </div>
                    <div className="col-12 col-lg-3">
                        <Select
                            value={selectedSupplier}
                            onChange={setSelectedSupplier}
                            options={supplierOptions}
                            isClearable
                            placeholder="Ta'minotchini tanlang..."
                        />
                    </div>
                    <div className="col-12 col-lg-3">
                        <Select
                            value={selectedFirm}
                            onChange={setSelectedFirm}
                            options={firmOptions}
                            isClearable
                            placeholder="Firmani tanlang..."
                        />
                    </div>
                    <div className="col-12 col-lg-3">
                        <Select
                            value={selectedMaterial}
                            onChange={setSelectedMaterial}
                            options={materialOptions}
                            isClearable
                            placeholder="Materialni tanlang..."
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {loading ? (
                                    <TableSpinner/>
                                ) : (
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Material</th>
                                                <th scope="col">Ta'minotchi</th>
                                                <th scope="col">Firma</th>
                                                <th scope="col">Miqdor</th>
                                                <th scope="col">Narx</th>
                                                <th scope="col">Jami</th>
                                                <th scope="col">Sana</th>
                                                <th scope="col">Yaratilgan sana</th>
                                                <th scope="col">Amallar</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {materialImports?.map((materialImport, index) => (
                                                <MaterialImportItem
                                                    key={materialImport.id}
                                                    materialImport={materialImport}
                                                    number={index + 1}
                                                />
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {numberOfPages > 1 && (
                    <CustomPaginationWithManyPages
                        numberOfPages={numberOfPages}
                        current_page={currentPage}
                        getPageItems={getMaterialImports}
                    />
                )}
            </div>

            <MaterialImportCreateForm show={show} handleClose={handleClose}/>
            <MaterialImportUpdateForm/>
            <MaterialImportDeleteForm/>
        </Fragment>
    );
};

export default MaterialImports;
