import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { downloadSingleMaterialReport } from "../../actions/material";
import DatePicker from "react-datepicker";
import ButtonLoader from "../layout/ButtonLoader";

const SingleMaterialReport = ({ id }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [month, setMonth] = useState(moment().format('YYYY-MM'));
    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [fileFormat, setFileFormat] = useState('pdf');

    const handleYear = (date) => {
        setYear(date.getFullYear())
        setSelectedYear(date)
    }

    const onDownload = async (e) => {
        e.preventDefault();
        setLoading(true);
        let data = {};
        switch (type) {
            case 'range':
                data = { period_type: 'range', from_date: fromDate, to_date: toDate, format: fileFormat };
                break;
            case 'monthly':
                data = { period_type: 'monthly', month: month, format: fileFormat };
                break;
            case 'yearly':
                data = { period_type: 'yearly', year: year.toString(), format: fileFormat };
                break;
        }
        const link = await dispatch(downloadSingleMaterialReport(id, data));

        if (link !== false && link !== undefined)
            window.open(link);
            
        setLoading(false);
    }

    return (
        <form className="row g-3">
            <div className="col-auto">
                <label htmlFor="to_date" className="visually-hidden">Hisobot turi</label>
                <select className={'form-control form-select'}
                        value={type}
                        onChange={e => setType(e.target.value)}
                >
                    <option value="">Hisobot turi</option>
                    <option value="range">Dan-Gacha</option>
                    <option value="monthly">Oylik</option>
                    <option value="yearly">Yillik</option>
                </select>
            </div>

            {
                'range' === type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="from_date" className="visually-hidden">Dan</label>
                            <input type="date" className="form-control" id="from_date"
                                   onChange={e => setFromDate(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                   value={fromDate}
                            />
                        </div>
                        <div className="col-auto">
                            <label htmlFor="to_date" className="visually-hidden">Gacha</label>
                            <input type="date" className="form-control" id="to_date"
                                   onChange={e => setToDate(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                   value={toDate}
                            />
                        </div>
                    </>
                )
            }
            {
                'monthly' === type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="month" className="visually-hidden">Dan</label>
                            <input type="month" className="form-control" id="month"
                                   onChange={e => setMonth(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM')}
                                   value={month}
                            />
                        </div>
                    </>
                )
            }
            {
                'yearly' === type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="year" className="visually-hidden">Yil</label>
                            <DatePicker
                                className={'form-control'}
                                selected={selectedYear}
                                onChange={(date) => handleYear(date)}
                                dateFormat="yyyy"
                                maxDate={new Date()}
                                showYearPicker
                                yearItemNumber={9}
                            />
                        </div>
                    </>
                )
            }

            {
                type !== '' && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="to_date" className="visually-hidden">Fayl formati</label>
                            <select className={'form-control form-select'}
                                    value={fileFormat}
                                    onChange={e => setFileFormat(e.target.value)}
                            >
                                <option value="pdf">PDF</option>
                                <option value="xlsx">Excel (xlsx)</option>
                            </select>
                        </div>

                        <div className="col-auto">
                            <button type="button" className="btn btn-primary mb-3" onClick={onDownload} disabled={loading}>
                                {loading ? <ButtonLoader /> : 'Yuklash'}
                            </button>
                        </div>
                    </>
                )
            }
        </form>
    )
}

export default SingleMaterialReport;