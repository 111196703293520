import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setDeleteMaterialImportId, setUpdateMaterialImportId, downloadMaterialImportDocument } from "../../actions/materialImports";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';
import { Download } from 'react-feather';
import ButtonLoader from "../layout/ButtonLoader";

const formatMoney = (value, seperator = ',') => {
    if (value || value === 0) {
        // Format with exactly 2 decimal places
        const formattedValue = Number(value).toFixed(2);
        return formattedValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, seperator);
    }
    return '0.00';
}

const MaterialImportItem = ({
    setDeleteMaterialImportId,
    setUpdateMaterialImportId,
    downloadMaterialImportDocument,
    number,
    materialImport: {
        id,
        material,
        quantity,
        price,
        date,
        created_at,
        supplier,
        warehouse,
        warehouse_id
    }
}) => {
    const { userCan } = usePermission();
    const [isLoading, setIsLoading] = useState(false);

    if (!userCan('view material imports')) {
        return null;
    }

    const setDeleteMaterialImport = (id) => {
        setDeleteMaterialImportId(id);
    };

    const setUpdateMaterialImport = (id) => {
        setUpdateMaterialImportId(id);
    };

    const onDownload = async (id) => {
        setIsLoading(true);
        const data = await downloadMaterialImportDocument(id);
        if (data?.url) {
            window.open(data.url, '_blank');
        }
        setIsLoading(false);
    };

    return (
        <tr>
            <th scope="row">{number}</th>
            <td>{material?.name}</td>
            <td>{supplier?.name}</td>
            <td>{warehouse?.name}</td>
            <td>{formatMoney(quantity)}</td>
            <td>{formatMoney(price)}</td>
            <td>{formatMoney(quantity * price)}</td>
            <td>{moment(date).format('DD-MM-YYYY')}</td>
            <td>{moment(created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
            <td>
                <Button
                    variant="outline-primary"
                    size="sm"
                    className="me-2"
                    onClick={() => onDownload(id)}
                    disabled={isLoading}
                >
                    {isLoading ? <ButtonLoader /> : <Download size={14} />}
                </Button>
                {userCan('edit material imports') && (
                    <Button
                        variant="outline-warning"
                        size="sm"
                        className="me-2"
                        onClick={() => setUpdateMaterialImport(id)}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                )}
                {userCan('delete material imports') && (
                    <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => setDeleteMaterialImport(id)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                )}
            </td>
        </tr>
    );
};

MaterialImportItem.propTypes = {
    materialImport: PropTypes.shape({
        id: PropTypes.number.isRequired,
        material: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        }).isRequired,
        supplier: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        }).isRequired,
        warehouse: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        }).isRequired,
        quantity: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired
    }).isRequired,
    setDeleteMaterialImportId: PropTypes.func.isRequired,
    setUpdateMaterialImportId: PropTypes.func.isRequired,
    downloadMaterialImportDocument: PropTypes.func.isRequired,
    number: PropTypes.number.isRequired
};

export default connect(null, {
    setDeleteMaterialImportId,
    setUpdateMaterialImportId,
    downloadMaterialImportDocument
})(MaterialImportItem);
