import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { createMaterialImport } from '../../actions/materialImports';
import { getMaterials } from '../../actions/material';
import { getSuppliers } from '../../actions/supplier';
import { getWarehouses } from '../../actions/warehouse';
import { usePermission } from '../../hooks/usePermission';
import MaterialImportItemForm from './MaterialImportItemForm';
import { Plus } from 'react-feather';

const MaterialImportCreateForm = ({
    show,
    handleClose,
    createMaterialImport,
    getMaterials,
    getSuppliers,
    getWarehouses,
    materials = [],
    suppliers = [],
    warehouses = []
}) => {
    const { userCan } = usePermission();

    if (!userCan('create material imports')) {
        return null;
    }

    const [formData, setFormData] = useState({
        supplier_id: '',
        warehouse_id: '',
        date: new Date(),
        is_for_special_sale: false,
        items: [{ material_id: '', quantity: '', price: '' }]
    });

    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    useEffect(() => {
        getMaterials();
        getSuppliers();
        getWarehouses();
    }, [getMaterials, getSuppliers, getWarehouses]);

    const { date, is_for_special_sale, items } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onItemChange = (index, field, value) => {
        const newItems = [...items];
        // Convert quantity and price to numbers with 2 decimal places
        if (field === 'quantity' || field === 'price') {
            value = value === '' ? '' : Number(parseFloat(value).toFixed(2));
        }
        newItems[index] = { ...newItems[index], [field]: value };
        setFormData({ ...formData, items: newItems });
    };

    const addItem = () => {
        setFormData({
            ...formData,
            items: [...items, { material_id: '', quantity: '', price: '' }]
        });
    };

    const removeItem = (index) => {
        if (items.length > 1) {
            const newItems = items.filter((_, i) => i !== index);
            setFormData({ ...formData, items: newItems });
        }
    };

    const onSubmit = e => {
        e.preventDefault();
        // Convert all quantities and prices to numbers with 2 decimal places
        const processedItems = items.map(item => ({
            ...item,
            quantity: Number(parseFloat(item.quantity || 0).toFixed(2)),
            price: Number(parseFloat(item.price || 0).toFixed(2))
        }));
        createMaterialImport({
            ...formData,
            items: processedItems,
            supplier_id: selectedSupplier?.value,
            warehouse_id: selectedWarehouse?.value,
            date: date.toISOString().split('T')[0]
        });
        handleClose();
        setFormData({
            supplier_id: '',
            warehouse_id: '',
            date: new Date(),
            is_for_special_sale: false,
            items: [{ material_id: '', quantity: '', price: '' }]
        });
        setSelectedSupplier(null);
        setSelectedWarehouse(null);
    };

    const materialOptions = materials?.map(material => ({
        value: material.id,
        label: material.name
    })) || [];

    const supplierOptions = suppliers?.map(supplier => ({
        value: supplier.id,
        label: supplier.name
    })) || [];

    const warehouseOptions = warehouses?.map(warehouse => ({
        value: warehouse.id,
        label: warehouse.name
    })) || [];

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Add Material Import</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3">
                                <Form.Label>Supplier</Form.Label>
                                <Select
                                    value={selectedSupplier}
                                    onChange={setSelectedSupplier}
                                    options={supplierOptions}
                                    placeholder="Select supplier..."
                                    isSearchable={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3">
                                <Form.Label>Warehouse</Form.Label>
                                <Select
                                    value={selectedWarehouse}
                                    onChange={setSelectedWarehouse}
                                    options={warehouseOptions}
                                    placeholder="Select warehouse..."
                                    isSearchable={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3">
                                <Form.Label>Date</Form.Label>
                                <DatePicker
                                    selected={date}
                                    onChange={date => setFormData({ ...formData, date })}
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label="Is for special sale"
                            name="is_for_special_sale"
                            checked={is_for_special_sale}
                            onChange={e => setFormData({ ...formData, is_for_special_sale: e.target.checked })}
                        />
                    </Form.Group>

                    <div className="border rounded p-3 mb-3">
                        <h5 className="mb-3">Items</h5>
                        {items.map((item, index) => (
                            <MaterialImportItemForm
                                key={index}
                                item={item}
                                index={index}
                                materialOptions={materialOptions}
                                onChange={onItemChange}
                                onRemove={removeItem}
                            />
                        ))}
                        <Button 
                            variant="outline-primary" 
                            onClick={addItem}
                            className="w-100"
                        >
                            <Plus size={16} className="me-2" />
                            Add Item
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

MaterialImportCreateForm.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    createMaterialImport: PropTypes.func.isRequired,
    getMaterials: PropTypes.func.isRequired,
    getSuppliers: PropTypes.func.isRequired,
    getWarehouses: PropTypes.func.isRequired,
    materials: PropTypes.array,
    suppliers: PropTypes.array,
    warehouses: PropTypes.array
};

const mapStateToProps = state => ({
    materials: state.material.materials,
    suppliers: state.supplier.suppliers,
    warehouses: state.warehouse.warehouses
});

export default connect(mapStateToProps, { 
    createMaterialImport, 
    getMaterials,
    getSuppliers,
    getWarehouses 
})(MaterialImportCreateForm);
