import React, {useEffect, useState} from 'react';
import useQueryParams from "../../utils/hooks/useQueryParams";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";
import ReactPaginate from "react-paginate";

const scrollToTop = (x = 0, y = 0) => {
    window.scroll(x, y);
};

const CustomPaginationWithManyPages = props => {
    const dispatch = useDispatch();
    const {page} = useQueryParams();
    const {getPageItems, numberOfPages, current_page, items, custom_params = {}} = props;
    const [innerCurrentPage, setInnerCurrentPage] = useState(page ? Number(page) : 1);

    useEffect(() => {
        const params = getParams(true); // Keep all params including page
        const new_params = {...params, ...custom_params};
        dispatch(getPageItems(innerCurrentPage, new_params));
    }, [])

    useEffect(() => {
        if (page && Number(page) !== Number(innerCurrentPage)) {
            setInnerCurrentPage(Number(page));
        }
    }, [page])

    const handlePageClick = (event) => {
        const newPage = event.selected + 1;
        const url = new URL(window.location);
        const params = getParams(true); // Keep all existing params
        
        // Update URL with all existing params plus the new page
        Object.keys(params).forEach(key => {
            if (key !== 'page') {
                url.searchParams.set(key, params[key]);
            }
        });
        url.searchParams.set('page', newPage);
        
        window.history.pushState({}, '', url);
        setInnerCurrentPage(Number(newPage));
        
        // Keep all existing params when fetching data
        const new_params = {...params, ...custom_params};
        if (new_params.page) delete new_params.page;
        dispatch(getPageItems(newPage, new_params));
        scrollToTop();
    }

    return numberOfPages > 1 ?
        <ReactPaginate
            className={'pagination'}
            breakClassName={'page-item'}
            nextClassName={'page-item'}
            activeClassName={'active'}
            activeLinkClassName={'active'}
            nextLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            breakLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            pageClassName={'page-item'}
            breakLabel="..."
            nextLabel="Keyingi >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={numberOfPages}
            forcePage={innerCurrentPage - 1}
            previousLabel="< Ortga"
            renderOnZeroPageCount={null}
        />
        : '';
}

export default CustomPaginationWithManyPages;
