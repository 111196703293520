import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_EXPENSE_TEMPLATE, CLEAR_ERRORS,
    EXPENSE_TEMPLATE_ERROR, GET_EXPENSE_TEMPLATE,
    GET_EXPENSE_TEMPLATES,
    REMOVE_DELETE_EXPENSE_TEMPLATE,
    REMOVE_UPDATE_EXPENSE_TEMPLATE,
    SET_DELETE_EXPENSE_TEMPLATE_ID,
    SET_UPDATE_EXPENSE_TEMPLATE_ID,
    SET_LOADING
} from './types';

export const getExpenseTemplate = (expenseTemplateID) => async (dispatch) => {
    try {
        const res = await axios.get(`/expense-templates/${expenseTemplateID}`);
        dispatch({
            type: GET_EXPENSE_TEMPLATE,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getExpenseTemplates = (page = 1, filters = {}) => async (dispatch) => {
    dispatch({type: SET_LOADING, payload: true})
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/expense-templates?page=${page}${queryParams}`);

        dispatch({
            type: GET_EXPENSE_TEMPLATES,
            payload: res.data,
        });
        dispatch({type: SET_LOADING, payload: false})
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addExpenseTemplate = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/expense-templates/', formData, config);

        dispatch(getExpenseTemplates());

        dispatch({
            type: ADD_EXPENSE_TEMPLATE,
            payload: res.data,
        });

        dispatch(setAlert('Xarajat shabloni yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: EXPENSE_TEMPLATE_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Harajat shabloni yaratilmadi !", 'danger'));
        }
    }
};

export const deleteExpenseTemplate = (deleteExpenseTemplateID) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.delete(`/expense-templates/${deleteExpenseTemplateID}`, config);

        dispatch({
            type: REMOVE_DELETE_EXPENSE_TEMPLATE
        });

        dispatch(getExpenseTemplates());

        dispatch(setAlert("Xarajat shabloni o'chirildi", 'success'));

        dispatch(clearErrors());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: EXPENSE_TEMPLATE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const updateExpenseTemplate = (updateExpenseTemplateID, updateformData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.put(`/expense-templates/${updateExpenseTemplateID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_EXPENSE_TEMPLATE});

        dispatch(getExpenseTemplates());

        dispatch(setAlert('Xarajat shabloni tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert("Xatolik yuzberdi", 'danger'));
        dispatch({
            type: EXPENSE_TEMPLATE_ERROR,
            payload: err.response.data
        });
    }
};

export const setDeleteExpenseTemplateId = (expense_template_ID) => async (dispatch) => {

    try {
        if (expense_template_ID !== null) {
            dispatch({
                type: SET_DELETE_EXPENSE_TEMPLATE_ID,
                payload: {
                    expense_template_ID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_EXPENSE_TEMPLATE
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateExpenseTemplateId = (expense_template_ID) => async (dispatch) => {

    try {
        if (expense_template_ID !== null) {
            dispatch({
                type: SET_UPDATE_EXPENSE_TEMPLATE_ID,
                payload: {
                    expense_template_ID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_EXPENSE_TEMPLATE});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};
