import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { deleteMaterialImport, setDeleteMaterialImportId } from '../../actions/materialImports';
import { usePermission } from '../../hooks/usePermission';

const MaterialImportDeleteForm = ({
    deleteMaterialImport,
    setDeleteMaterialImportId,
    materialImportId
}) => {
    const { userCan } = usePermission();

    if (!userCan('delete material imports')) {
        return null;
    }

    const handleClose = () => {
        setDeleteMaterialImportId(null);
    };

    const handleDelete = () => {
        deleteMaterialImport(materialImportId);
        handleClose();
    };

    return (
        <Modal show={!!materialImportId} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Material Import</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this material import?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

MaterialImportDeleteForm.propTypes = {
    deleteMaterialImport: PropTypes.func.isRequired,
    setDeleteMaterialImportId: PropTypes.func.isRequired,
    materialImportId: PropTypes.number
};

const mapStateToProps = state => ({
    materialImportId: state.materialImport.deleteMaterialImportId
});

export default connect(mapStateToProps, {
    deleteMaterialImport,
    setDeleteMaterialImportId
})(MaterialImportDeleteForm);
