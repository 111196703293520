import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { Trash } from 'react-feather';

const MaterialImportItemForm = ({
    item,
    index,
    materialOptions,
    onChange,
    onRemove
}) => {
    return (
        <div className="border rounded p-3 mb-3 position-relative">
            <div className="row">
                <div className="col-md-4">
                    <Form.Group className="mb-3">
                        <Form.Label>Material</Form.Label>
                        <Select
                            value={materialOptions.find(option => option.value === item.material_id)}
                            onChange={(selected) => onChange(index, 'material_id', selected.value)}
                            options={materialOptions}
                            placeholder="Select material..."
                            isSearchable={true}
                        />
                    </Form.Group>
                </div>
                <div className="col-md-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter quantity"
                            value={item.quantity}
                            onChange={(e) => onChange(index, 'quantity', e.target.value)}
                            required
                        />
                    </Form.Group>
                </div>
                <div className="col-md-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter price"
                            value={item.price}
                            onChange={(e) => onChange(index, 'price', e.target.value)}
                            required
                        />
                    </Form.Group>
                </div>
                <div className="col-md-2 d-flex align-items-end">
                    <Button 
                        variant="outline-danger" 
                        className="mb-3"
                        onClick={() => onRemove(index)}
                    >
                        <Trash size={16} />
                    </Button>
                </div>
            </div>
        </div>
    );
};

MaterialImportItemForm.propTypes = {
    item: PropTypes.shape({
        material_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired,
    index: PropTypes.number.isRequired,
    materialOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default MaterialImportItemForm;
