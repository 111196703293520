import axios from 'axios';
import { setAlert } from './alert';
import {
    GET_MATERIAL_IMPORTS_LIST,
    GET_MATERIAL_IMPORTS_LIST_ERROR,
    ADD_MATERIAL_IMPORT_ITEM,
    ADD_MATERIAL_IMPORT_ITEM_ERROR,
    UPDATE_MATERIAL_IMPORT_ITEM,
    UPDATE_MATERIAL_IMPORT_ITEM_ERROR,
    DELETE_MATERIAL_IMPORT_ITEM,
    DELETE_MATERIAL_IMPORT_ITEM_ERROR,
    SET_DELETE_MATERIAL_IMPORT_ITEM_ID,
    SET_UPDATE_MATERIAL_IMPORT_ITEM_ID,
    GET_MATERIAL_IMPORTS_COUNT,
    GET_MATERIAL_IMPORTS_COUNT_ERROR
} from './types';

// Get material imports
export const getMaterialImports = (page = 1, filters = {}) => async dispatch => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/material-imports?page=${page}${queryParams}`);
        
        dispatch({
            type: GET_MATERIAL_IMPORTS_LIST,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_MATERIAL_IMPORTS_LIST_ERROR,
            payload: { msg: err.response?.data?.message || 'Server Error', status: err.response?.status }
        });
    }
};

// Get material imports count
export const getMaterialImportsCount = (filters = {}) => async dispatch => {
    try {
        const params = new URLSearchParams(filters).toString();
        const res = await axios.get(`/material-imports/count?${params}`);
        
        dispatch({
            type: GET_MATERIAL_IMPORTS_COUNT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_MATERIAL_IMPORTS_COUNT_ERROR,
            payload: { msg: err.response?.data?.message || 'Server Error', status: err.response?.status }
        });
    }
};

// Create material import
export const createMaterialImport = formData => async dispatch => {
    try {
        const res = await axios.post('/material-imports', formData);

        dispatch({
            type: ADD_MATERIAL_IMPORT_ITEM,
            payload: res.data
        });

        dispatch(setAlert('Material import created successfully', 'success'));
    } catch (err) {
        dispatch({
            type: ADD_MATERIAL_IMPORT_ITEM_ERROR,
            payload: { msg: err.response?.data?.message || 'Server Error', status: err.response?.status }
        });

        dispatch(setAlert('Error creating material import', 'danger'));
    }
};

// Update material import
export const updateMaterialImport = (id, formData) => async dispatch => {
    try {
        const res = await axios.put(`/material-imports/${id}`, formData);

        dispatch({
            type: UPDATE_MATERIAL_IMPORT_ITEM,
            payload: res.data
        });

        dispatch(setAlert('Material import updated successfully', 'success'));
    } catch (err) {
        dispatch({
            type: UPDATE_MATERIAL_IMPORT_ITEM_ERROR,
            payload: { msg: err.response?.data?.message || 'Server Error', status: err.response?.status }
        });

        dispatch(setAlert('Error updating material import', 'danger'));
    }
};

// Delete material import
export const deleteMaterialImport = id => async dispatch => {
    try {
        await axios.delete(`/material-imports/${id}`);

        dispatch({
            type: DELETE_MATERIAL_IMPORT_ITEM,
            payload: id
        });

        dispatch(setAlert('Material import deleted successfully', 'success'));
    } catch (err) {
        dispatch({
            type: DELETE_MATERIAL_IMPORT_ITEM_ERROR,
            payload: { msg: err.response?.data?.message || 'Server Error', status: err.response?.status }
        });

        dispatch(setAlert('Error deleting material import', 'danger'));
    }
};

// Set delete material import id
export const setDeleteMaterialImportId = id => dispatch => {
    dispatch({
        type: SET_DELETE_MATERIAL_IMPORT_ITEM_ID,
        payload: id
    });
};

// Set update material import id
export const setUpdateMaterialImportId = id => dispatch => {
    dispatch({
        type: SET_UPDATE_MATERIAL_IMPORT_ITEM_ID,
        payload: id
    });
};

// Download material import document
export const downloadMaterialImportDocument = (importId) => async (dispatch) => {
    try {
        const response = await axios.get(`/material-imports/${importId}/download_document`);
        return response.data;
    } catch (err) {
        dispatch(setAlert("Yuklashda muommo bo'ldi!", 'danger'));
    }
};
