import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {setUpdateFirmId, updateFirm, getAvailableManagerUsers, getAvailableAccountantUsers, getAvailableStorekeeperUsers} from "../../actions/firms";
import Button from "react-bootstrap/Button";
import {getParams} from "../../utils/hooks/getParams";

const FirmUpdateForm = () => {
    const dispatch = useDispatch();
    const firm = useSelector(state => state.firm);
    const {error, loading, update_firm, available_manager_for_firms, available_accountant_for_firms, available_storekeeper_for_firms} = firm || {};

    const defaultValues = {
        name: '',
        manager_id: 0,
        accountant_id: 0,
        storekeeper_id: 0,
        address: '',
        phone: '',
        mfo: '',
        inn: '',
        bank_account: '',
    };
    
    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const formRef = useRef();

    useEffect(() => {
        if (update_firm) {
            setInputs({
                name: update_firm.name || '',
                manager_id: update_firm.manager_id || 0,
                accountant_id: update_firm.accountant_id || 0,
                storekeeper_id: update_firm.storekeeper_id || 0,
                address: update_firm.address || '',
                phone: update_firm.phone || '',
                mfo: update_firm.mfo || '',
                inn: update_firm.inn || '',
                bank_account: update_firm.bank_account || '',
            });
            dispatch(getAvailableManagerUsers({
                is_available_manager: true,
                firm_id: update_firm.id
            }));
            dispatch(getAvailableAccountantUsers({
                is_available_accountant: true,
                firm_id: update_firm.id
            }));
            dispatch(getAvailableStorekeeperUsers({
                is_available_storekeeper: true,
                firm_id: update_firm.id
            }));
        } else {
            setInputs(defaultValues);
        }
    }, [update_firm, dispatch]);

    const handleChange = e => {
        setErrors({});
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }

    const closeModal = () => {
        dispatch(setUpdateFirmId(null));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            const data = {
                name: inputs.name,
                manager_id: inputs.manager_id,
                accountant_id: inputs.accountant_id,
                storekeeper_id: inputs.storekeeper_id,
                address: inputs.address,
                phone: inputs.phone,
                mfo: inputs.mfo,
                inn: inputs.inn,
                bank_account: inputs.bank_account,
            };
            
            await dispatch(updateFirm(update_firm.id, data, getParams()));
            
            if (!error) {
                closeModal();
                setInputs(defaultValues);
                setErrors({});
            }
        } catch (err) {
            if (err.response?.data?.errors) {
                setErrors(err.response.data.errors);
            }
        }
    };

    useEffect(() => {
        if (error && !loading) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (errorsItems) {
                Object.keys(errorsItems).forEach(item => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error, loading]);

    return (
        <Modal show={!!update_firm} onHide={closeModal}>
            <Modal.Header closeButton className="bg-light">
                <Modal.Title className="text-black">Firmani tahrirlash</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-3 pb-4 mx-3">
                <form data-testid="update-firm-form" onSubmit={handleSubmit} ref={formRef}>
                    <div className="mb-3">
                        <label htmlFor="name">Nomi</label>
                        <input
                            id="name"
                            name="name"
                            className={'form-control'}
                            type="text"
                            value={inputs.name || ''}
                            onChange={handleChange}
                            placeholder="kiriting"
                        />
                        <div className="error">{errors?.name}</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="phone">Telefon</label>
                        <input
                            id="phone"
                            name="phone"
                            className={'form-control'}
                            type="text"
                            value={inputs.phone || ''}
                            onChange={handleChange}
                            placeholder="kiriting"
                        />
                        <div className="error">{errors?.phone}</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mfo">MFO</label>
                        <input
                            id="mfo"
                            name="mfo"
                            className={'form-control'}
                            type="text"
                            value={inputs.mfo || ''}
                            onChange={handleChange}
                            placeholder="kiriting"
                        />
                        <div className="error">{errors?.mfo}</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inn">INN</label>
                        <input
                            id="inn"
                            name="inn"
                            className={'form-control'}
                            type="text"
                            value={inputs.inn || ''}
                            onChange={handleChange}
                            placeholder="kiriting"
                        />
                        <div className="error">{errors?.inn}</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="bank_account">Bank hisob(р.сч)</label>
                        <input
                            id="bank_account"
                            name="bank_account"
                            className={'form-control'}
                            type="text"
                            value={inputs.bank_account || ''}
                            onChange={handleChange}
                            placeholder="kiriting"
                        />
                        <div className="error">{errors?.bank_account}</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="manager_id">Menejer</label>
                        <select
                            id="manager_id"
                            name="manager_id"
                            className={'form-control'}
                            value={inputs.manager_id || ''}
                            onChange={handleChange}
                        >
                            <option value="">Tanlang</option>
                            {available_manager_for_firms?.map(manager => (
                                <option key={manager.id} value={manager.id}>
                                    {manager.name}
                                </option>
                            ))}
                        </select>
                        <div className="error">{errors?.manager_id}</div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="accountant_id">Hisobchi</label>
                        <select
                            id="accountant_id"
                            name="accountant_id"
                            className={'form-control'}
                            value={inputs.accountant_id || ''}
                            onChange={handleChange}
                        >
                            <option value="">Tanlang</option>
                            {available_accountant_for_firms?.map(accountant => (
                                <option key={accountant.id} value={accountant.id}>
                                    {accountant.name}
                                </option>
                            ))}
                        </select>
                        <div className="error">{errors?.accountant_id}</div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="storekeeper_id">Omborchi</label>
                        <select
                            id="storekeeper_id"
                            name="storekeeper_id"
                            className={'form-control'}
                            value={inputs.storekeeper_id || ''}
                            onChange={handleChange}
                        >
                            <option value="">Tanlang</option>
                            {available_storekeeper_for_firms?.map(storekeeper => (
                                <option key={storekeeper.id} value={storekeeper.id}>
                                    {storekeeper.name}
                                </option>
                            ))}
                        </select>
                        <div className="error">{errors?.storekeeper_id}</div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="address">Manzil</label>
                        <input
                            id="address"
                            name="address"
                            className={'form-control'}
                            type="text"
                            value={inputs.address || ''}
                            onChange={handleChange}
                            placeholder="kiriting"
                        />
                        <div className="error">{errors?.address}</div>
                    </div>
                    <div className="text-end">
                        <Button variant="secondary" className="me-2" onClick={closeModal}>
                            Bekor qilish
                        </Button>
                        <Button variant="primary" type="submit" disabled={loading}>
                            Saqlash
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default FirmUpdateForm;