import {
    GET_MATERIAL_IMPORTS_LIST,
    GET_MATERIAL_IMPORTS_LIST_ERROR,
    ADD_MATERIAL_IMPORT_ITEM,
    ADD_MATERIAL_IMPORT_ITEM_ERROR,
    UPDATE_MATERIAL_IMPORT_ITEM,
    UPDATE_MATERIAL_IMPORT_ITEM_ERROR,
    DELETE_MATERIAL_IMPORT_ITEM,
    DELETE_MATERIAL_IMPORT_ITEM_ERROR,
    SET_DELETE_MATERIAL_IMPORT_ITEM_ID,
    SET_UPDATE_MATERIAL_IMPORT_ITEM_ID,
    GET_MATERIAL_IMPORTS_COUNT,
    GET_MATERIAL_IMPORTS_COUNT_ERROR
} from '../actions/types';

const initialState = {
    materialImports: [],
    materialImport: null,
    loading: true,
    error: {},
    deleteMaterialImportId: null,
    updateMaterialImportId: null,
    numberOfPages: 1,
    currentPage: 1,
    total_material_imports: 0
};

// Helper function to parse decimal numbers
const parseDecimal = (value) => {
    if (!value && value !== 0) return 0;
    // Convert string to number and fix to 2 decimal places
    return Number(parseFloat(value).toFixed(2));
};

// Helper function to ensure numeric values
const processImportData = (data) => {
    if (Array.isArray(data)) {
        return data.map(item => ({
            ...item,
            quantity: parseDecimal(item.quantity),
            price: parseDecimal(item.price)
        }));
    }
    return {
        ...data,
        quantity: parseDecimal(data.quantity),
        price: parseDecimal(data.price)
    };
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_MATERIAL_IMPORTS_LIST:
            return {
                ...state,
                materialImports: processImportData(payload.data),
                numberOfPages: payload.last_page,
                currentPage: payload.current_page,
                total_material_imports: payload.total,
                loading: false
            };
        case GET_MATERIAL_IMPORTS_COUNT:
            return {
                ...state,
                total_material_imports: payload.total,
                loading: false
            };
        case ADD_MATERIAL_IMPORT_ITEM:
            return {
                ...state,
                materialImports: [processImportData(payload.data), ...state.materialImports],
                loading: false
            };
        case UPDATE_MATERIAL_IMPORT_ITEM:
            return {
                ...state,
                materialImports: state.materialImports.map(materialImport =>
                    materialImport.id === payload.data.id ? processImportData(payload.data) : materialImport
                ),
                loading: false,
                updateMaterialImportId: null
            };
        case DELETE_MATERIAL_IMPORT_ITEM:
            return {
                ...state,
                materialImports: state.materialImports.filter(materialImport => materialImport.id !== payload),
                loading: false,
                deleteMaterialImportId: null
            };
        case SET_DELETE_MATERIAL_IMPORT_ITEM_ID:
            return {
                ...state,
                deleteMaterialImportId: payload
            };
        case SET_UPDATE_MATERIAL_IMPORT_ITEM_ID:
            return {
                ...state,
                updateMaterialImportId: payload
            };
        case GET_MATERIAL_IMPORTS_LIST_ERROR:
        case ADD_MATERIAL_IMPORT_ITEM_ERROR:
        case UPDATE_MATERIAL_IMPORT_ITEM_ERROR:
        case DELETE_MATERIAL_IMPORT_ITEM_ERROR:
        case GET_MATERIAL_IMPORTS_COUNT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
