import React, {Fragment, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ExpenseDeleteForm from "./ExpenseDeleteForm";
import ExpenseUpdateForm from "./ExpenseUpdateForm";
import {getExpenses, getExpenseTemplateIdData} from "../../actions/expense";
import ExpenseCreateForm from "./ExpenseCreateForm";
import ExpenseItem from "./ExpenseItem";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import TableSpinner from "../layout/TableSpinner";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import Select from 'react-select';
import {getFirms} from "../../actions/firms";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePermission } from '../../hooks/usePermission';
import useQueryParams from "../../utils/hooks/useQueryParams";

const Expenses = () => {
    const dispatch = useDispatch();
    const { userCan } = usePermission();
    const {expense, firm} = useSelector(state => state);
    const {expenses, loading, numberOfPages, currentPage, total_expenses, expense_template_id_data} = expense;
    const {firms} = firm;
    const { page, firm_id, expense_template_id, start_date, end_date, search } = useQueryParams();

    if (!userCan('view expenses')) {
        return <div>You don't have permission to view expenses.</div>;
    }

    const [show, setShow] = useState(false);
    const [selectedFirm, setSelectedFirm] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    // Initialize filters from URL params
    useEffect(() => {
        dispatch(getFirms());
        dispatch(getExpenseTemplateIdData());
    }, [dispatch]);

    useEffect(() => {
        // Set initial states from URL params
        if (firms?.length && firm_id) {
            const firm = firms.find(f => f.id === Number(firm_id));
            if (firm) {
                setSelectedFirm({ value: firm.id, label: firm.name });
            }
        }
    }, [firms, firm_id]);

    useEffect(() => {
        if (expense_template_id_data?.length && expense_template_id) {
            const template = expense_template_id_data.find(t => t.id === Number(expense_template_id));
            if (template) {
                setSelectedTemplate({ value: template.id, label: template.name });
            }
        }
    }, [expense_template_id_data, expense_template_id]);

    useEffect(() => {
        if (start_date || end_date) {
            setDateRange([
                start_date ? new Date(start_date) : null,
                end_date ? new Date(end_date) : null
            ]);
        }
    }, [start_date, end_date]);

    // Update URL and fetch data when filters change
    useEffect(() => {
        const url = new URL(window.location);
        const filters = {};

        if (selectedFirm) {
            filters.firm_id = selectedFirm.value;
            url.searchParams.set('firm_id', selectedFirm.value);
        } else {
            url.searchParams.delete('firm_id');
        }

        if (selectedTemplate) {
            filters.expense_template_id = selectedTemplate.value;
            url.searchParams.set('expense_template_id', selectedTemplate.value);
        } else {
            url.searchParams.delete('expense_template_id');
        }

        if (startDate) {
            const formattedStartDate = startDate.toISOString().split('T')[0];
            filters.start_date = formattedStartDate;
            url.searchParams.set('start_date', formattedStartDate);
        } else {
            url.searchParams.delete('start_date');
        }

        if (endDate) {
            const formattedEndDate = endDate.toISOString().split('T')[0];
            filters.end_date = formattedEndDate;
            url.searchParams.set('end_date', formattedEndDate);
        } else {
            url.searchParams.delete('end_date');
        }

        if (search) {
            filters.search = search;
            url.searchParams.set('search', search);
        }

        // Keep the current page if it exists
        if (page) {
            url.searchParams.set('page', page);
        }

        window.history.pushState({}, '', url);
        dispatch(getExpenses(page || 1, filters));
    }, [selectedFirm, selectedTemplate, startDate, endDate, search, page]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const firmOptions = firms?.map(firm => ({
        value: firm.id,
        label: firm.name
    })) || [];

    const templateOptions = expense_template_id_data?.map(template => ({
        value: template.id,
        label: template.name
    })) || [];

    return (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> harajatlar ({total_expenses}) </h1>
                    </div>
                    <div className="col-md-12 col-lg-4 text-end">
                        {userCan('create expenses') && (
                            <button className="btn btn-primary" onClick={handleShow}>
                                <Plus size={18}/> Qo'shish
                            </button>
                        )}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                        <Select
                            value={selectedFirm}
                            onChange={setSelectedFirm}
                            options={firmOptions}
                            placeholder="Firma tanlang..."
                            isClearable={true}
                        />
                    </div>
                    <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                        <Select
                            value={selectedTemplate}
                            onChange={setSelectedTemplate}
                            options={templateOptions}
                            placeholder="Shablon tanlang..."
                            isClearable={true}
                        />
                    </div>
                    <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            className="form-control"
                            placeholderText="Sanani tanlang..."
                            dateFormat="dd.MM.yyyy"
                            isClearable
                        />
                    </div>
                    <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                        <SearchForm getPageItems={getExpenses}/>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <div className="card w-100 table-responsive">
                            {loading ? (
                                <div data-testid="table-spinner" className="text-center mt-5">
                                    <TableSpinner/>
                                </div>
                            ) : (
                                <table className="table custom-table table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Nomi</th>
                                            <th scope="col">Kategoriya</th>
                                            <th scope="col">Firma</th>
                                            <th scope="col">Miqdori</th>
                                            <th scope="col">Narxi</th>
                                            <th scope="col">Summa</th>
                                            <th scope="col">Sana</th>
                                            <th scope="col">Tavsifi</th>
                                            <th scope="col">Amal vaqti</th>
                                            <th scope="col">Amallar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {expenses.map((item, index) => (
                                            <ExpenseItem 
                                                key={item.id} 
                                                number={index + 1} 
                                                expense={item}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getExpenses}
                            current_page={currentPage}
                        />
                    </div>
                </div>
            </div>

            {userCan('create expenses') && (
                <ExpenseCreateForm 
                    show={show} 
                    handleShow={handleShow} 
                    handleClose={handleClose}
                />
            )}

            {userCan('edit expenses') && (
                <ExpenseUpdateForm/>
            )}

            {userCan('delete expenses') && (
                <ExpenseDeleteForm/>
            )}
        </Fragment>
    );
};

export default Expenses;
