import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { updateMaterialImport, setUpdateMaterialImportId } from '../../actions/materialImports';
import { getMaterials } from '../../actions/material';
import { usePermission } from '../../hooks/usePermission';
import MaterialImportItemForm from './MaterialImportItemForm';
import { Plus } from 'react-feather';

const MaterialImportUpdateForm = ({
    updateMaterialImport,
    setUpdateMaterialImportId,
    getMaterials,
    materials,
    materialImportId,
    materialImports,
    suppliers,
    warehouses
}) => {
    const { userCan } = usePermission();

    if (!userCan('edit material imports')) {
        return null;
    }

    const [formData, setFormData] = useState({
        supplier_id: '',
        warehouse_id: '',
        date: new Date(),
        is_for_special_sale: false,
        items: []
    });

    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    useEffect(() => {
        getMaterials();
    }, [getMaterials]);

    useEffect(() => {
        if (materialImportId && materialImports) {
            const materialImport = materialImports.find(mi => mi.id === materialImportId);
            if (materialImport) {
                setFormData({
                    supplier_id: materialImport.supplier_id,
                    warehouse_id: materialImport.warehouse_id,
                    date: new Date(materialImport.date),
                    is_for_special_sale: materialImport.is_for_special_sale,
                    items: materialImport.items.map(item => ({
                        id: item.id,
                        material_id: item.material_id,
                        quantity: item.quantity,
                        price: item.price
                    }))
                });
                
                setSelectedSupplier({
                    value: materialImport.supplier_id,
                    label: materialImport.supplier?.name
                });
                
                setSelectedWarehouse({
                    value: materialImport.warehouse_id,
                    label: materialImport.warehouse?.name
                });
            }
        }
    }, [materialImportId, materialImports]);

    const { date, is_for_special_sale, items } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onItemChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index] = { ...newItems[index], [field]: value };
        setFormData({ ...formData, items: newItems });
    };

    const addItem = () => {
        setFormData({
            ...formData,
            items: [...items, { material_id: '', quantity: '', price: '' }]
        });
    };

    const removeItem = (index) => {
        if (items.length > 1) {
            const newItems = items.filter((_, i) => i !== index);
            setFormData({ ...formData, items: newItems });
        }
    };

    const onSubmit = e => {
        e.preventDefault();
        updateMaterialImport(materialImportId, {
            ...formData,
            supplier_id: selectedSupplier.value,
            warehouse_id: selectedWarehouse.value,
            date: date.toISOString().split('T')[0]
        });
        handleClose();
    };

    const handleClose = () => {
        setUpdateMaterialImportId(null);
        setFormData({
            supplier_id: '',
            warehouse_id: '',
            date: new Date(),
            is_for_special_sale: false,
            items: []
        });
        setSelectedSupplier(null);
        setSelectedWarehouse(null);
    };

    const materialOptions = materials?.map(material => ({
        value: material.id,
        label: material.name
    })) || [];

    const supplierOptions = suppliers?.map(supplier => ({
        value: supplier.id,
        label: supplier.name
    })) || [];

    const warehouseOptions = warehouses?.map(warehouse => ({
        value: warehouse.id,
        label: warehouse.name
    })) || [];

    return (
        <Modal show={!!materialImportId} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Update Material Import</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3">
                                <Form.Label>Supplier</Form.Label>
                                <Select
                                    value={selectedSupplier}
                                    onChange={setSelectedSupplier}
                                    options={supplierOptions}
                                    placeholder="Select supplier..."
                                    isSearchable={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3">
                                <Form.Label>Warehouse</Form.Label>
                                <Select
                                    value={selectedWarehouse}
                                    onChange={setSelectedWarehouse}
                                    options={warehouseOptions}
                                    placeholder="Select warehouse..."
                                    isSearchable={true}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3">
                                <Form.Label>Date</Form.Label>
                                <DatePicker
                                    selected={date}
                                    onChange={date => setFormData({ ...formData, date })}
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label="Is for special sale"
                            name="is_for_special_sale"
                            checked={is_for_special_sale}
                            onChange={e => setFormData({ ...formData, is_for_special_sale: e.target.checked })}
                        />
                    </Form.Group>

                    <div className="border rounded p-3 mb-3">
                        <h5 className="mb-3">Items</h5>
                        {items.map((item, index) => (
                            <MaterialImportItemForm
                                key={index}
                                item={item}
                                index={index}
                                materialOptions={materialOptions}
                                onChange={onItemChange}
                                onRemove={removeItem}
                            />
                        ))}
                        <Button 
                            variant="outline-primary" 
                            onClick={addItem}
                            className="w-100"
                        >
                            <Plus size={16} className="me-2" />
                            Add Item
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

MaterialImportUpdateForm.propTypes = {
    updateMaterialImport: PropTypes.func.isRequired,
    setUpdateMaterialImportId: PropTypes.func.isRequired,
    getMaterials: PropTypes.func.isRequired,
    materials: PropTypes.array,
    suppliers: PropTypes.array,
    warehouses: PropTypes.array,
    materialImportId: PropTypes.number,
    materialImports: PropTypes.array
};

const mapStateToProps = state => ({
    materials: state.material.materials,
    suppliers: state.supplier.suppliers,
    warehouses: state.warehouse.warehouses,
    materialImportId: state.materialImport.updateMaterialImportId,
    materialImports: state.materialImport.materialImports
});

export default connect(mapStateToProps, { updateMaterialImport, setUpdateMaterialImportId, getMaterials })(MaterialImportUpdateForm);
