import React, {} from 'react';
import { Switch} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import PrivateRoute from '../routing/PrivateRoute';
// import Alert from '../layout/Alert';
import Dashboard from '../dashboard/Dashboard';

import Users from '../users/Users';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Employees from "../employees/Employees";
import Clients from "../clients/Clients";
import Suppliers from "../suppliers/Suppliers";
import Firms from "../firms/Firms";
import Warehouses from "../warehouses/Warehouses";
import Transports from "../transports/Transports";
import Units from "../units/Units";
import UnitAlternatives from "../unitalternatives/UnitAlternatives";
import Products from "../products/Products";
import Materials from "../materials/Materials";
import MaterialImports from "../material_imports/MaterialImports";
import Salaries from "../salaries/Salaries";
import Expenses from "../expenses/Expenses";
import ExpenseTemplates from "../expense_templates/ExpenseTemplates";
import Profile from "../dashboard/Profile";
import UserPage from "../users/UserPage";
import EmployeePage from "../employees/EmployeePage";
import SpareParts from "../spare_parts/SpareParts";
import MaterialPage from "../materials/MaterialPage";
import WarehousePage from "../warehouses/WarehousePage";
import UnitPage from "../units/UnitPage";
import UnitAlternativePage from "../unitalternatives/UnitAlternativePage";
import SupplierPage from "../suppliers/SupplierPage";
import ClientPage from "../clients/ClientPage";
import TransportPage from "../transports/TransportPage";
import ExpansePage from "../expenses/ExpensePage";
import ExpenseTemplatePage from "../expense_templates/ExpenseTemplatePage";
import FirmPage from "../firms/FirmPage";
import ProductPage from "../products/ProductPage";
import SparePartPage from "../spare_parts/SparePartPage";
import ProductDeliveries from "../product_deliveries/ProductDeliveries";
import ProductDeliveryPage from "../product_deliveries/ProductDeliveryPage";
import Sales from "../sales/Sales";
import SalePage from "../sales/SalePage";
import EmployeeCalculationsNew from "../employees_transactions_new/EmployeeCalculationsNew";
import {clearAlerts} from "../../actions/alert";
import MaterialCalculationsNew from "../material_transactions_new/MaterialCalculationsNew";
import SupplierCalculationsNew from "../suppliers_transactions_new/SupplierCalculationsNew";
import ClientCalculationsNew from "../clients_transactions_new/ClientCalculationsNew";
import ProductCalculationsNew from "../product_transactions_new/ProductCalculationsNew";
import SuppliersMaterialCalculationsNew from "../suppliers_material_transactions_new/SuppliersMaterialCalculationsNew";
import ProductsMaterialCalculationsNew from "../products_material_transactions_new/ProductsMaterialCalculationsNew";
import Productions from "../productions/Productions";
import ProductionPage from "../productions/ProductionPage";
import RolesList from '../roles/RolesList';
import PermissionsList from '../permissions/PermissionsList';
import PagesList from '../pages/PagesList';
import PermissionGuard from '../auth/PermissionGuard';

import '../../custom.css';

const Routes = ({toggleSideBar,sidebar: {isShown, isExpanded}, auth: {isAuthenticated}}) => {

    let condition = true;

    if (isShown && isExpanded === false) {
        condition = false;
    }

    if (isShown !== true) {
        condition = false;
    }

    if (isMobile) {
        condition = false;
    }

    return (
        <section className="container-fluid pt-4">
            {/* <Alert /> */}

            <div className="row justify-content-end">
                <div className={`${isExpanded ? 'col-lg-12 with_sidebar' : 'col-lg-12 without_sidebar'}`}>
                    <Switch>
                        {isAuthenticated ? (
                            <>
                                <PrivateRoute exact path="/dashboard">
                                    <PermissionGuard requiredPermission="view dashboard">
                                        <Dashboard/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/roles">
                                    <PermissionGuard requiredPermission="view roles">
                                        <RolesList/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/permissions">
                                    <PermissionGuard requiredPermission="view permissions">
                                        <PermissionsList/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/users">
                                    <PermissionGuard requiredPermission="view users">
                                        <Users/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/users/:id">
                                    <PermissionGuard requiredPermission="view users">
                                        <UserPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/employees">
                                    <PermissionGuard requiredPermission="view employees">
                                        <Employees/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/employees/:id">
                                    <PermissionGuard requiredPermission="view employees">
                                        <EmployeePage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/clients">
                                    <PermissionGuard requiredPermission="view clients">
                                        <Clients/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/clients/:id">
                                    <PermissionGuard requiredPermission="view clients">
                                        <ClientPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/suppliers">
                                    <PermissionGuard requiredPermission="view suppliers">
                                        <Suppliers/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/suppliers/:id">
                                    <PermissionGuard requiredPermission="view suppliers">
                                        <SupplierPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/firms">
                                    <PermissionGuard requiredPermission="view firms">
                                        <Firms/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/firms/:id">
                                    <PermissionGuard requiredPermission="view firms">
                                        <FirmPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/warehouses/:id">
                                    <PermissionGuard requiredPermission="view warehouses">
                                        <WarehousePage/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/warehouses">
                                    <PermissionGuard requiredPermission="view warehouses">
                                        <Warehouses/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/transports">
                                    <PermissionGuard requiredPermission="view transports">
                                        <Transports/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/transports/:id">
                                    <PermissionGuard requiredPermission="view transports">
                                        <TransportPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/units">
                                    <PermissionGuard requiredPermission="view units">
                                        <Units/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/units/:id">
                                    <PermissionGuard requiredPermission="view units">
                                        <UnitPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/unitalternatives">
                                    <PermissionGuard requiredPermission="view unitalternatives">
                                        <UnitAlternatives/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/unitalternatives/:id">
                                    <PermissionGuard requiredPermission="view unitalternatives">
                                        <UnitAlternativePage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/products">
                                    <PermissionGuard requiredPermission="view products">
                                        <Products/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/products/:id">
                                    <PermissionGuard requiredPermission="view products">
                                        <ProductPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/productions">
                                    <PermissionGuard requiredPermission="view productions">
                                        <Productions/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/productions/:id">
                                    <PermissionGuard requiredPermission="view productions">
                                        <ProductionPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/materials">
                                    <PermissionGuard requiredPermission="view materials">
                                        <Materials/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/materials/:id">
                                    <PermissionGuard requiredPermission="view materials">
                                        <MaterialPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/material-imports">
                                    <PermissionGuard requiredPermission="view material imports">
                                        <MaterialImports/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/employee-calculations">
                                    <PermissionGuard requiredPermission="view employee calculations">
                                        <EmployeeCalculationsNew/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/supplier-calculations">
                                    <PermissionGuard requiredPermission="view supplier calculations">
                                        <SupplierCalculationsNew/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/client-calculations">
                                    <PermissionGuard requiredPermission="view client calculations">
                                        <ClientCalculationsNew/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/supplier-material-calculations">
                                    <PermissionGuard requiredPermission="view supplier material calculations">
                                        <SuppliersMaterialCalculationsNew/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/product-material-calculations">
                                    <PermissionGuard requiredPermission="view product material calculations">
                                        <ProductsMaterialCalculationsNew/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/product-calculations">
                                    <PermissionGuard requiredPermission="view product calculations">
                                        <ProductCalculationsNew/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/material-calculations">
                                    <PermissionGuard requiredPermission="view material calculations">
                                        <MaterialCalculationsNew/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/salaries">
                                    <PermissionGuard requiredPermission="view salaries">
                                        <Salaries/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/expenses">
                                    <PermissionGuard requiredPermission="view expenses">
                                        <Expenses/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/expenses/:id">
                                    <PermissionGuard requiredPermission="view expenses">
                                        <ExpansePage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/expense-templates">
                                    <PermissionGuard requiredPermission="view expense templates">
                                        <ExpenseTemplates/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/expense-templates/:id">
                                    <PermissionGuard requiredPermission="view expense-templates">
                                        <ExpenseTemplatePage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/profile">
                                    <PermissionGuard requiredPermission="view profile">
                                        <Profile/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/spare-parts">
                                    <PermissionGuard requiredPermission="view spare-parts">
                                        <SpareParts/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/spare-parts/:id">
                                    <PermissionGuard requiredPermission="view spare-parts">
                                        <SparePartPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/product-deliveries">
                                    <PermissionGuard requiredPermission="view product-deliveries">
                                        <ProductDeliveries/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/product-deliveries/:id">
                                    <PermissionGuard requiredPermission="view product-deliveries">
                                        <ProductDeliveryPage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/sales">
                                    <PermissionGuard requiredPermission="view sales">
                                        <Sales/>
                                    </PermissionGuard>
                                </PrivateRoute>
                                <PrivateRoute exact path="/sales/:id">
                                    <PermissionGuard requiredPermission="view sales">
                                        <SalePage/>
                                    </PermissionGuard>
                                </PrivateRoute>

                                <PrivateRoute exact path="/pages">
                                    <PermissionGuard requiredPermission="view pages">
                                        <PagesList/>
                                    </PermissionGuard>
                                </PrivateRoute>
                            </>
                        ) : 'no page found'}
                    </Switch>
                </div>
            </div>
        </section>
    );
};
Routes.propTypes = {
    auth: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    alert: state.alert,
    sidebar: state.sidebar,
});


export default connect(mapStateToProps, {clearAlerts})(
    Routes
);
